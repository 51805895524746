import './bootstrap';
import '../css/app.css';

import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-toastification/dist/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import '../css/custom-vue-multiselect.css';

import { createApp, h, DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import ToastPlugin from 'vue-toast-notification';
import Toast from 'vue-toastification';
import 'vue-select/dist/vue-select.css';
import { IoReload, RiComputerFill, IoCheckmarkCircle, CoDevices, HiSolidPencilAlt, BiApp, BiAppIndicator, BiChevronBarDown, BiChevronUp, BiChevronDown, MdKeyboarddoublearrowupTwotone, MdKeyboarddoublearrowdownTwotone, HiArrowUp, HiArrowDown, FaSort, BiFiletypePdf, BiPlus, FcExport, GiCheckMark, HiSolidLockClosed, HiSolidLockOpen, HiSolidPencil, IoBackspace, IoCheckmarkDone, IoClose, IoCloseCircle, IoExit, IoEye, IoEyeOff, IoNotifications, IoPencil, IoPerson, IoSearch, IoSend, MdClose, MdEdit, MdKeyboardarrowdown, MdKeyboardarrowleftOutlined, MdKeyboardarrowrightOutlined, MdMenu, MdMenuopen, MdRefresh, MdRemove, RiPencilFill, ViFileTypeExcel, ViFileTypePdf, MdDaterangeOutlined, BiTable } from 'oh-vue-icons/icons';
import { VueQueryPlugin } from '@tanstack/vue-query';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

addIcons(IoReload, RiComputerFill, IoCheckmarkCircle, CoDevices, HiSolidPencilAlt, IoSearch, RiPencilFill, IoBackspace, IoCheckmarkDone, IoPencil, IoSend, GiCheckMark, MdMenuopen, MdMenu, MdKeyboardarrowleftOutlined, MdKeyboardarrowrightOutlined, BiChevronBarDown, MdKeyboarddoublearrowupTwotone, MdKeyboarddoublearrowdownTwotone, HiArrowUp, HiArrowDown, FaSort, ViFileTypePdf, ViFileTypeExcel, BiApp, BiChevronUp, BiChevronDown, BiAppIndicator, BiFiletypePdf, BiPlus, FcExport, HiSolidLockClosed, HiSolidLockOpen, HiSolidPencil, IoClose, IoCloseCircle, IoExit, IoEye, IoEyeOff, IoNotifications, IoPerson, MdClose, MdEdit, MdKeyboardarrowdown, MdRefresh, MdRemove, RiPencilFill, MdDaterangeOutlined, BiTable);

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: "https://764d02a7130d6ed78054fb52cb475509@o4505329697619968.ingest.us.sentry.io/4507931471183872",
      integrations: [
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.component('v-select', vSelect);
    app.use(plugin);
    app.use(ZiggyVue);
    app.use(ToastPlugin);
    app.use(Toast);
    app.use(VueQueryPlugin);
    app.component('v-icon', OhVueIcon);
    app.mount(el);
  },
  progress: {
    color: '#4B5563',
  },
});

